var slider = (function(window, document, $, undefined) {
  return {
    init: function () {
      var swiper = new Swiper('.main-slider', {
        loop      : true,
        speed     : 1000,
        autoplay  : true,
        delay     : 5000,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      });

      var galleryTop = new Swiper('.product-detail-slider', {
        nextButton  : '.swiper-button-next',
        prevButton  : '.swiper-button-prev',
        spaceBetween: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      });



      var $thumbs = $('#js-product-detail-slider').find('.slider-thumb');
      $thumbs.on('click', function () {
        $thumbs.removeClass('active');
        $(this).addClass('active');
        galleryTop.slideTo($(this).index());
      });

      galleryTop.on('slideChange',function() {
        $thumbs.removeClass('active');
        $($thumbs[this.activeIndex]).addClass('active');
      })
    }
  };

})(window, document, jQuery, slider);

var templateScripting = (function(window, document, $, undefined) {
  return {
    init: function() {
      $(document).foundation();
    }
  }
})(window, document, jQuery, templateScripting);
